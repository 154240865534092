import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useCookies } from 'react-cookie';


export default function Home() {

    const [message, setmessage] = useState("");
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [subject, setsubject] = useState("");
    const [dis, setdis] = useState("");
    const [cookie,setCookie,removeCookie] =useCookies('access_token','refresh_token');
    const getToken =()=>{
     
        return cookie.access_token;
       
       }
    //    const token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const http= axios.create({
        baseURL:"https://api.unnoyan.com/api",
        headers:{
          'Content-Type': 'application/json',
          "Content-Type": "multipart/form-data",
        //   'Authorization':'Bearer '+getToken(),
        //   'X-CSRF-TOKEN': token,
          
  
        }
      });


useEffect(() => {
  

 

window.addEventListener('scroll', function () {

    const innerHeightHaf=window.innerHeight/2;
const home = document.querySelector("#home").offsetTop-innerHeightHaf;
const service = document.querySelector("#service").offsetTop-innerHeightHaf;
const about = document.querySelector("#about").offsetTop-innerHeightHaf;
const team = document.querySelector("#team").offsetTop-innerHeightHaf;
const contact = document.querySelector("#contact").offsetTop-innerHeightHaf;
const homemenu = document.querySelector(".thisisforhome");
const aboutmenu = document.querySelector(".thisisforabout");
const servicemenu = document.querySelector(".thisisforservice");
const teammenu = document.querySelector(".thisisforteam");
const contactmenu = document.querySelector(".thisisforcontact");

    if (window.scrollY >= home && window.scrollY < about) {
        homemenu.classList.add("active");

    } else {
        homemenu.classList.remove("active");
    }
    if (window.scrollY >= about && window.scrollY < service) {

        aboutmenu.classList.add("active");
    } else {
        aboutmenu.classList.remove("active");
    }

    if (window.scrollY >= service && window.scrollY < team) {

        servicemenu.classList.add("active");
    } else {
        servicemenu.classList.remove("active");
    }
    if (window.scrollY >= team && window.scrollY < contact) {

        teammenu.classList.add("active");
    } else {
        teammenu.classList.remove("active");
    }
    if (window.scrollY >= contact) {

        contactmenu.classList.add("active");
    } else {
        contactmenu.classList.remove("active");
    }
}, [])


})

const submitContact = (e) => {

    e.preventDefault();

    setmessage("Email checking...");

    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(email)) {
      setmessage("Please provide a valid email address");
      return false;
    }
    if (email) {

      setmessage("Reset link sending...please wait few moments.");

      const link = window.location.origin;
      http.post('/contact', { name:name,email: email, phone:phone,subject:subject,dis:dis }).then((res) => {


        if (res.data == 'noaccount') {
          setmessage("We did not find any account with this email !");
          return;
        }

        setmessage(res.data);


      }).catch((res) => {


        setmessage("Soneting Wrong !Please try again or contact me with others details, Thank You.");

      });


    }

  }

  return (
    <div>
         <header id="Header" className="header">
        <div className="logo">unnoyan</div>
        <nav>
            <li className="active thisisforhome"><a href="#home">Home</a></li>
            <li className="thisisforabout"><a href="#about">About</a></li>
            <li className="thisisforservice"><a href="#service">Service</a></li>
            <li className="thisisforteam"><a href="#team">Team</a></li>
            <li className="thisisforcontact"><a href="#contact">Contact</a></li>
        </nav>
    </header>
    <section id="home" className='sectionhome'>
        <div className="head">
            <div className="title">
                <h1>BUILD YOUR
                    CREATIVE
                    SOFTWER
                    WITH US</h1>
            </div>
            <div className="content">
                <div className="curcles">
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                </div>
            </div>
        </div>
        <div className="foot">
            <div className="lft">
                <div className="title"><h3>WE WORK FOR YOUR SATISFACTION</h3></div>
            </div>
            <div className="rit">
                <div className="wel"><img src="./images/showcase.png" alt=""/></div>
            </div>
        </div>
    </section>

    <section id="about">
        <div className="head">
            <div className="title">
                <h1>ABOUT UNNOYAN</h1>
                <h3>Unnoyan is a software solution zone where you have your software solutions 
                    unnoyan vision and mission is working for client satisfation</h3>
            </div>
            <div className="content">
                <div className="curcles">
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                </div>
            </div>
        </div>
        <div className="foot">
            <div className="lft">
                <div className="title"><h3>WE WORK FOR YOUR SATISFACTION</h3></div>
            </div>
            <div className="rit">
                <div className="wel"><img src="./images/showcase.png" alt=""/></div>
            </div>
        </div>
    </section>

    <section id="service">
        <div className="head">
            <div className="title">
                <h1>Choose a 
                    creative 
                    service
                    with us </h1>
            </div>
            <div className="content">
                <div className="curcles">
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                </div>
            </div>
        </div>
        <div className="foot">
            <div className="lft">
                <div className="title"><h3>WE WORK FOR YOUR SATISFACTION</h3></div>
            </div>
            <div className="rit">
                <div className="wel">
                    <img src="./images/showcase.png" alt=""/>
                    <div className="titleandsub">
                        <div className="titile">
                            UMS SOFTWARE
                        </div>
                        <div className="sub">
                            BULID YOUR UNIVERSITY MANAGEMENT 
SOFTWARE WITH US
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section id="team">
        <div className="head">
            <div className="title">
                <h1>Choose a team 
                    member for 
                    your software </h1>
                    <br/>
                    <h3>WE WORK FOR YOUR SATISFACTION</h3>
            </div>
            <div className="content">
                <div className="curcles">
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                </div>
            </div>
        </div>
        <div className="foot">
            <div className="lft">
               {/* <div className="title"><h3>WE WORK FOR YOUR SATISFACTION</h3></div>  */}
            </div>
            <div className="rit">
                <div className="wel">
                    <div className="teams">
                        <div className="team">
                            <div className="img"><img src="./images/team.jpg" alt=""/></div>
                     
                        <div className="dis">
                            <div className="nameandtitle">
                                <div className="name">Mohammed Tanzil</div>
                                <div className="title">Software head</div>
                            </div>
                            <div className="exprience">
                                <li><a href="#">Laravel</a></li>
                                <li><a href="#">React</a></li>
                                <li><a href="#">Mysql</a></li>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



    <section id="contact">
        <div className="head">
            <div className="title">
                <h1>Contact
                    with us</h1>
                    <br/>
                    <h3>WE WORK FOR YOUR SATISFACTION</h3>
            </div>
            <div className="content">
                <div className="curcles">
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                    <div className="cercle"></div>
                </div>
            </div>
        </div>
        <div className="foot">
            <div className="lft">
                <div className="address">
                    <li><a href="#"><i className="fa-solid fa-phone"></i> +88 01518793422</a></li>
                    <li><a href="#"> <i className="fa-solid fa-envelope"></i> support@unnoyan.com</a> </li>
                    <li><a href="#"><i className="fa-solid fa-location-dot"></i> House 44, Road 02, North badda
                        Hazipara, Dhaka-1212</a></li>
                </div>
            </div>
            <div className="rit">
                <div className="wel">
                    <div className="contactForm">
                        <form action="?" method="get" onSubmit={submitContact}>
                            <h2>Fill free know to us about your requerments </h2>
                            <input type="text" name="name" id="" placeholder="Enter your full name" 

                            onChange={(e)=>(setname(e.target.value))} 
                            value={name}
                            
                            />
                            <input type="text" name="email" id="" placeholder="Enter your valid email address"
                            onChange={(e)=>(setemail(e.target.value))} 
                            value={email}
                            />
                            <input type="text" name="phone" id="" placeholder="Enter your valid phone number"
                            onChange={(e)=>(setphone(e.target.value))} 
                            value={phone}
                            />
                            <input type="text" name="subject" id="" placeholder="What is the perpase of contact"
                            onChange={(e)=>(setsubject(e.target.value))} 
                            value={subject}
                            />
                            <textarea name="Descripetion" id="" cols="30" rows="10" placeholder="Describe your requerments or need "
                            onChange={(e)=>(setdis(e.target.value))} 
                            >{dis}</textarea>
                            <label>{message}</label>
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}
